import * as React from "react"

function ShowHeader(props){
    return (
        <div className="full-width h-80 showcase-header bg-cover" style={{background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${props.thumbnail})`, backgroundSize: `cover`, backgroundPosition: `center`}}>
             <h2 className="text-white text-4xl pt-40 font-black px-10 md:px-20 lg:px-24 xl:px-40">{props.title}</h2>
        </div>
    )
}
export default ShowHeader
