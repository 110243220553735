import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoCard from "../components/video-card"
import ShowHeader from "../components/show-header"

const ShowcaseTemplate = ({ data }) => {

  const allVideos = data.allVimeoVideo.nodes

  // State for the list
  const [list, setList] = useState([...allVideos.slice(0, 10)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allVideos.length > 10)

  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more videos
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allVideos.length
      const nextResults = isMore
        ? allVideos.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allVideos.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line


  return(
  <Layout>
    <Seo title="Shows" />
    <ShowHeader
      title={data.vimeoAlbum.title}
      thumbnail={data.vimeoAlbum.thumbnail}
    />
    <div className="grid grid-cols-1 showcase-top md:grid-cols-3 gap-10">
        {list.map((video) => (
            <VideoCard
            thumbnail={video.thumbnail}
            title={video.title}
            link={"/" + video.id}
            time={video.duration}
            date={video.date}
            />
        ))}
      </div>
      <div ref={loadRef} className="flex justify-center">
          {hasMore ? <p>Loading...</p> : <p></p>}
        </div>
  </Layout>
  )
}

export const query = graphql`
query($slug: String!, $title: String!) {
    vimeoAlbum (id: {eq: $slug}){
      id
      title
      thumbnail
    }
    allVimeoVideo(filter: {showcase: {elemMatch: {name: {eq: $title}}}}) {
      nodes {
        title
        thumbnail
        id
        duration
        date
      }
    }
  }
`

export default ShowcaseTemplate
